<!--展览详情-->
<template>
  <div class="main-div">
    <!--面包屑-->
    <el-row class="outerRow">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row class="outerRow">
      <el-col class="rowSpaceBetween">
        <!--   左侧菜单栏   -->
        <div class="leftDiv">
          <left-menu :menu-list="leftMenu" @menuActive="clickMenu"></left-menu>
        </div>

        <!--   右侧区域   -->
        <div class="rightDiv">
          <div class="rulesDiv">
            <div class="rulesTitle">
              {{menuName}}
            </div>
            <div class="line-class"></div>
            <div style="width: 100%; margin-bottom: 20px"></div>
            <div class="w-e-text-container">
              <div v-html="showData.infoTheme" v-if="menuIndex === 0" data-slate-editor></div>
              <div v-html="showData.schedule" v-if="menuIndex === 1" data-slate-editor></div>
              <div v-html="showData.matter" v-if="menuIndex === 2" data-slate-editor></div>
              <div v-html="showData.organization" v-if="menuIndex === 3" data-slate-editor></div>
              <div v-html="showData.sponsorChance" v-if="menuIndex === 4" data-slate-editor></div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import leftMenu from '@/components/leftMenu'
import axios from 'axios'


export default {
  name: 'showDetail',
  components: { topPathList, leftMenu },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标大厦',
          path: '/giEdifice/index'
        },
        {
          name: '展览安排',
          path: '/giEdifice/moreShow'
        },
        {
          name: '展览安排详情',
          path: '/giEdifice/showDetail'
        },
      ],
      leftMenu: [
        { name: '展会主题' },
        { name: '展会日程' },
        { name: '参与事项' },
        { name: '组织机构' },
        { name: '赞助机会' },
      ],
      menuIndex: 0,
      menuName: '',
      showData: {
        infoTheme: '',
        schedule: '',
        matter: '',
        organization: '',
        sponsorChance: '',
      }
    }
  },
  mounted () {
    this.menuName = this.leftMenu[0].name;
    this.initData()
  },
  methods: {
    clickMenu({ menu, index }) {
      this.menuName = menu.name;
      this.menuIndex = index
    },
    initData() {
      let id = this.$route.query.infoId; //文章id
      let params = {
        infoId: id,
      }
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/exhibit/detail",
        params: params,
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          let data = res.data.data;
          console.log(data)
          this.showData = data
        } else {
          this.$message.error('查询失败')
        }
      }).catch(err => {
        this.$message.error('接口调用异常')
      })
    },
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
::v-deep .w-e-text-container img {
  max-width: 100% !important;
}
.main-div {
  background-color: #f8f9fa !important;
}
.leftDiv {
  width: 15%;
  text-align: center;
}
.rightDiv {
  width: 82%;
  background: white;
  border-radius: 5px;
  margin-bottom: 40px;
}
.rulesDiv {
  transition: all .2s ease;
  background: #FFFFFF;
  box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 23px 24px;
  min-height: 517px;

  .rulesTitle {
    font-size: 20px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #1A5BC5;
    line-height: 30px;
    text-align: left;
  }

  .line-class {
    margin-top: 20px;
    width: 910px;
    border: 1px solid #EBEFF6;
  }
}
</style>
